import React from 'react'
import Breadcrumbs from '../component/breadcrumbs/Breadcrumbs'; 
import Portfolios from '../component/portfolio/Portfolio'; 

const Portfolio = () => {
  return (
    <div>
          <Breadcrumbs/> 
          <Portfolios/>  
    </div>
  )
}

export default Portfolio
