import React, { useEffect, useState } from "react";
import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl'; 
import Parser from 'html-react-parser';
import Breadcrumbs from '../component/breadcrumbs/Breadcrumbs'; 


const Testimonials = () => {

  let [feedbacks, setFeedback] = useState([]);  
 
  React.useEffect(() => {                           
    RestClient.GetRequest(AppUrl.Feedback).then(result=>{
      setFeedback(result); 
    }) 
  },[]); 

  return (
    <div>
        <Breadcrumbs/> 

        <section id="testimonials" class="testimonials section-bg">
      <div class="container">

        <div class="row">
      {feedbacks.map((feedback) => (   
          <div class="col-lg-6 aos-init aos-animate" data-aos="fade-up">
            <div class="testimonial-item">
              <img src={`assets/img/testimonials/${feedback.feedback_img}`} class="testimonial-img" alt=""/>
              <h3>{feedback.feedback_heading}</h3>
              <h4>{feedback.feedback_h2}</h4>
              <p>
                <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                {feedback.feedback_para}
                <i class="bx bxs-quote-alt-right quote-icon-right"></i>
              </p> 
            </div>
          </div>
      ))} 

        </div>

      </div>
    </section>
       
    </div>
  )
}

export default Testimonials
 