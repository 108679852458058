import React, { useEffect, useState } from "react";
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl'; 
import Parser from 'html-react-parser';

const Faq = () => {

  let [faqs, setFaqs] = useState([]);  
  
  React.useEffect(() => {                           
    RestClient.GetRequest(AppUrl.Faq).then(result=>{
      setFaqs(result);    
    })     
  },[]); 

  let [informations, setInformation] = useState([]);  
    
  React.useEffect(() => {                           
    RestClient.GetRequest(AppUrl.Information).then(result=>{
      setInformation(result); 
    })
  },[]);  
 

  return (
    <div>
         <section id="faq" class="faq section-bg">
      <div class="container aos-init aos-animate" data-aos="fade-up">

       {informations.map((info) => ( 
        <div class="section-title">
          <h2>{info.frq}</h2>
        </div>   
      ))} 
        <div class="faq-list">
          <ul>

          {faqs.map((faq) => (  

            <li data-aos="fade-up" class="aos-init aos-animate">
              <i class="bx bx-help-circle icon-help"></i> 
              <a data-toggle="collapse" class="collapse" href={`#faq-list-1${faq.id}`}>{faq.faq_quetion} <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
              <div id={`faq-list-1${faq.id}`} class="collapse show" data-parent=".faq-list">
                <p>  
                {faq.faq_answer}
                </p>
              </div>
            </li>
         ))}  

          </ul>
        </div>

      </div>
    </section>
    </div>
  )
}

export default Faq
