class AppUrl {
    
    // static BaseURL = 'http://127.0.0.1:8000/api';  

    static BaseURL = 'https://laravel.soulighthealingwithneha.com/api';    
    
    static Slider = this.BaseURL+'/slider';        
    static About = this.BaseURL+'/about_us';  
    static Services = this.BaseURL+'/services'; 
    static Portfolio = this.BaseURL+'/portfolio';   
 
    static Feedback = this.BaseURL+'/feedback'; 
    static Client = this.BaseURL+'/clients'; 
    static Team = this.BaseURL+'/team'; 
    static Information = this.BaseURL+'/information'; 
   
    static Skill = this.BaseURL+'/skills';   
    static Features = this.BaseURL+'/features';    
    static Faq = this.BaseURL+'/faq';   

    static Blogs = this.BaseURL+'/blogs'; 
    static BlogsDetails = this.BaseURL+'/blogsdetails/';     

    static Contact = this.BaseURL+'/contact';  
    static Contacts = this.BaseURL+'/contacts';  
    static Footer = this.BaseURL+'/footer';   
      
}

export default AppUrl