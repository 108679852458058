import React, { useEffect, useState } from "react";
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl'; 
import Parser from 'html-react-parser';  
import { Link } from "react-router-dom";

const BlogSidebar = () => {

    let [blogs, setBlogs] = useState([]);  
 
    React.useEffect(() => {                           
      RestClient.GetRequest(AppUrl.Blogs).then(result=>{
        setBlogs(result);
      })  
    },[]); 

  return (
    <div>
        <div class="sidebar aos-init aos-animate" data-aos="fade-left">

<h3 class="sidebar-title">Search</h3>
<div class="sidebar-item search-form">
  <form action="">
    <input type="text"/>
    <button type="submit"><i class="icofont-search"></i></button>
  </form>

</div>

<h3 class="sidebar-title">Categories</h3>
<div class="sidebar-item categories">
  <ul>
    <li><a href="#">General <span>(25)</span></a></li>
    <li><a href="#">Lifestyle <span>(12)</span></a></li>
    <li><a href="#">Travel <span>(5)</span></a></li>
    <li><a href="#">Design <span>(22)</span></a></li>
    <li><a href="#">Creative <span>(8)</span></a></li>
    <li><a href="#">Educaion <span>(14)</span></a></li>
  </ul>

</div>



<h3 class="sidebar-title">Recent Posts</h3>
<div class="sidebar-item recent-posts">

  {blogs.map((blog) => (  

  <div class="post-item clearfix">
    <img src={`assets/img/${blog.blog_img}`} alt=""/>
    <h4><Link to={`/blogsdetails/${blog.id}`}> {blog.blog_heading} </Link> 
    
    </h4>  
    <time datetime="2020-01-01">Jan 1, 2020</time>
  </div>

  ))}
</div>



<h3 class="sidebar-title">Tags</h3>
<div class="sidebar-item tags">
  <ul>
    <li><a href="#">App</a></li>
    <li><a href="#">IT</a></li>
    <li><a href="#">Business</a></li>
    <li><a href="#">Business</a></li>
    <li><a href="#">Mac</a></li>
    <li><a href="#">Design</a></li>
    <li><a href="#">Office</a></li>
    <li><a href="#">Creative</a></li>
    <li><a href="#">Studio</a></li>
    <li><a href="#">Smart</a></li>
    <li><a href="#">Tips</a></li>
    <li><a href="#">Marketing</a></li>
  </ul>

</div>

</div>
    </div>
  )
}

export default BlogSidebar
