import React from 'react'
import Breadcrumbs from '../component/breadcrumbs/Breadcrumbs'; 
import Teams from '../component/team/Team'; 

const Team = () => {  
  return (
    <div>
      <Breadcrumbs/> 
      <Teams/> 
    </div>
  )
}

export default Team
