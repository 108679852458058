import React from 'react'
import Breadcrumbs from '../component/breadcrumbs/Breadcrumbs'; 
import Blog from '../component/blog/Blog'; 
// import SingleBlog from '../component/blog/single_blog'; 

const Blogs = () => {
  return (
    <div>
      <Breadcrumbs/>   
      <Blog/> 
    </div>  
  )
}

export default Blogs
