import React from 'react';
import ReactDOM from 'react-dom/client';  
import { BrowserRouter } from 'react-router-dom'
import './index.css';    
import App from './App';
import Header from './common/Header'; 
import Footer from './common/Footer';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';   


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <React.StrictMode>
    <BrowserRouter>
  <Header/>
  <Footer/>
  </BrowserRouter>

</React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
