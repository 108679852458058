import React from 'react'
import Breadcrumbs from '../component/breadcrumbs/Breadcrumbs'; 
import Abouts from '../component/about/About'; 
import Teams from '../component/team/Team'; 
import Skill from '../component/skill/Skill'; 
import Clients from '../component/clients/Clients';   

const About = () => {
  return (
    <div> 
       <Breadcrumbs/>
       <Abouts/>
       <Teams/> 
       {/* <Skill/> */}
       <Clients/>
    </div>
  )
}

export default About
