import React from 'react'
import Breadcrumbs from '../component/breadcrumbs/Breadcrumbs'; 
import Service from '../component/services/Services'; 
import Features from '../component/features/Features';  

const Services = () => {
  return (
    <div>
          <Breadcrumbs/>
          <Service/> 
          <Features/>
    </div>
  )
} 

export default Services
