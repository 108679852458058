import React, { useEffect, useState } from "react";
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl'; 
import Parser from 'html-react-parser';  
import { Link } from "react-router-dom";
import BlogSidebar from './BlogSidebar';    
 
const Blog = () => {

  let [blogs, setBlogs] = useState([]);  
 
  React.useEffect(() => {                           
    RestClient.GetRequest(AppUrl.Blogs).then(result=>{
      setBlogs(result);
    })  
  },[]); 

  return (
    <div> 
      <section id="blog" class="blog">
      <div class="container">

        <div class="row">

          <div class="col-lg-8 entries">

          {blogs.map((blog) => (   

            <article class="entry aos-init aos-animate" data-aos="fade-up">

              <div class="entry-img">
                <img src={`assets/img/${blog.blog_img}`} alt="" class="img-fluid"/>
              </div>
 
              <h2 class="entry-title">
                <a href="blog-single.html">{blog.blog_heading}</a>
              </h2>

              <div class="entry-meta">
                <ul>
                  <li class="d-flex align-items-center"><i class="icofont-user"></i> <a href="blog-single.html">John Doe</a></li>
                  <li class="d-flex align-items-center"><i class="icofont-wall-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jan 1, 2020</time></a></li>
                  <li class="d-flex align-items-center"><i class="icofont-comment"></i> <a href="blog-single.html">12 Comments</a></li>
                </ul>
              </div>

              <div class="entry-content">
                <p>
                {blog.blog_para}
                </p> 
                <div class="read-more">
          
                   <Link to={`/blogsdetails/${blog.id}`}> View More </Link> 
                </div>     
              </div> 

            </article>
               ))}  

            <div class="blog-pagination">
              <ul class="justify-content-center">
                <li class="disabled"><i class="icofont-rounded-left"></i></li>
                <li><a href="#">1</a></li>
                <li class="active"><a href="#">2</a></li>
                <li><a href="#">3</a></li>
                <li><a href="#"><i class="icofont-rounded-right"></i></a></li>
              </ul>
            </div>

          </div>
        

          <div class="col-lg-4">

            <BlogSidebar/>

          </div>

        </div>

      </div>
    </section>
    </div>
  )
}

export default Blog
