import React, { useEffect, useState } from "react";
import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl'; 
import Parser from 'html-react-parser';  
import Home from "../pages/Home"; 
import About from "../pages/About";  
import Services from "../pages/Services"; 
import { BrowserRouter as Router, Routes, 
  Route,Link} from "react-router-dom"; 
 
const Footer = () => {

  let [footers, setFooters] = useState([]);  
 
  React.useEffect(() => {                           
    RestClient.GetRequest(AppUrl.Footer).then(result=>{
      setFooters(result); 
    })          
  },[]);  


  return ( 
    <div>
      
<footer id="footer">

<div class="footer-top">
  <div class="container">
    <div class="row">
    {footers.map((footer) => (
 
      <div class="col-lg-3 col-md-6 footer-contact">
        <h3>Company</h3>
       {Parser(footer.footer_address)}
      </div>
                                                            
    ))}

      <div class="col-lg-2 col-md-6 footer-links">
        <h4>Useful Links</h4> 
        <ul>
          <li><i class="bx bx-chevron-right"></i><Link to="/">Home</Link></li>
          <li><i class="bx bx-chevron-right"></i><Link to="/about">About Us</Link></li>
          <li><i class="bx bx-chevron-right"></i><Link to="/services">Services</Link></li>
          <li><i class="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li>
          <li><i class="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li>
        </ul>
      </div>
 
      <div class="col-lg-3 col-md-6 footer-links">
        <h4>Our Services</h4>  
        <ul>
          <li><i class="bx bx-chevron-right"></i> <a href="#">Web Design</a></li>
          <li><i class="bx bx-chevron-right"></i> <a href="#">Web Development</a></li>
          <li><i class="bx bx-chevron-right"></i> <a href="#">Product Management</a></li>
          <li><i class="bx bx-chevron-right"></i> <a href="#">Marketing</a></li>
          <li><i class="bx bx-chevron-right"></i> <a href="#">Graphic Design</a></li>
        </ul>
      </div>

      <div class="col-lg-4 col-md-6 footer-newsletter">
        <h4>Join Our Newsletter</h4>
        <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
        <form action="" method="post">
          <input type="email" name="email"/><input type="submit" value="Subscribe"/>
        </form>
      </div>

    </div>
  </div>
</div>

{footers.map((footer) => (
<div class="container d-md-flex py-4">

  <div class="mr-md-auto text-center text-md-left">
      
{Parser(footer.copyright)}
      
  </div>
 
  <div class="social-links text-center text-md-right pt-3 pt-md-0">
  {Parser(footer.social)}
  </div>
</div>
))}
</footer>

 

    </div>
  ) 
}
 
export default Footer
