import React from 'react'
import Breadcrumbs from '../component/breadcrumbs/Breadcrumbs'; 
import Price from '../component/pricing/Price'; 
import Faq from '../component/faqs/Faq'; 

const Pricing = () => { 
  return (
    <div>
      <Breadcrumbs/>
      {/* <Price/> */}
      <Faq/> 
    </div>
  )
}

export default Pricing
