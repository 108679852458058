import React, { useEffect, useState } from "react";
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl'; 
import Parser from 'html-react-parser'; 
 
const Clients = () => {

  let [clients, setClient] = useState([]);  

  React.useEffect(() => {                           
    RestClient.GetRequest(AppUrl.Client).then(result=>{
      setClient(result); 
    }) 
  },[]); 

  let [informations, setInformation] = useState([]);  
    
  React.useEffect(() => {                           
    RestClient.GetRequest(AppUrl.Information).then(result=>{
      setInformation(result); 
    })
  },[]);  

  

  return (
    <div>
    <section id="clients" class="clients">
      <div class="container" data-aos="fade-up">
      {informations.map((info) => ( 
        <div class="section-title">
          <h2>{info.client}</h2>
        </div>   
      ))}
        <div class="row no-gutters clients-wrap clearfix" data-aos="fade-up">
      {clients.map((client) => (  
          <div class="col-lg-3 col-md-4 col-6">
            <div class="client-logo">
              <img src={`assets/img/clients/${client.client_img}`} class="img-fluid"  alt=""/>
            </div> 
          </div>
 
       ))} 

        </div>

      </div>
    </section>
    </div>   
  ) 
} 

export default Clients
