import React from 'react'
import Slider from '../component/slider/Slider'; 
import About from '../component/about/About'; 
import Services from '../component/services/Services'; 
import Portfolio from '../component/portfolio/Portfolio'; 
import Clients from '../component/clients/Clients';   



const Home = () => { 
  return (
    <div> 
      <Slider/> 
      <About/>
      <Services/>
      <Portfolio/>
      <Clients/>
    </div>
  )

} 
 
export default Home