import React, { useEffect, useState } from "react";
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl'; 
import Parser from 'html-react-parser';

const Team = () => {

  let [teams, setTeams] = useState([]); 
 
  React.useEffect(() => {                           
    RestClient.GetRequest(AppUrl.Team).then(result=>{
      setTeams(result);
    })
  },[]);  

  let [informations, setInformation] = useState([]);  
    
  React.useEffect(() => {                           
    RestClient.GetRequest(AppUrl.Information).then(result=>{
      setInformation(result); 
    })
  },[]);  

  return (
    <div> 
          <section id="team" class="team section-bg">
      <div class="container">
      {informations.map((info) => ( 
        <div class="section-title aos-init aos-animate" data-aos="fade-up">
            {Parser(info.team)} 
        </div> 
      ))}
        <div class="row">

    {teams.map((team) => (  

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div class="member aos-init aos-animate" data-aos="fade-up">
              <div class="member-img">
                <img src={`assets/img/team/${team.team_img}`} class="img-fluid" alt=""/>
                <div class="social">
                  <a href={team.twitter}><i class="icofont-twitter"></i></a>
                  <a href={team.facebook}><i class="icofont-facebook"></i></a>
                  <a href={team.instagram}><i class="icofont-instagram"></i></a>
                  <a href={team.linkdin}><i class="icofont-linkedin"></i></a>
                </div>  
              </div>
              <div class="member-info">
                 {Parser(team.team_name)}  
              </div>
            </div>
          </div>

     ))} 

        </div>

      </div>
    </section>
    </div>
  )
}

export default Team
