import React from 'react';
import './App.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Header from './common/Header';
import Footer from './common/Footer';
import Home from './pages/Home';

function App() {
  return (
    <div> 
    <React.StrictMode>
              {/* <Header/>
               <Home/> 
              <Footer/> */}
      </React.StrictMode>
      </div>
  );
}

export default App;
