import React, { useEffect, useState } from "react";
import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl'; 
import Parser from 'html-react-parser';
import axios from 'axios';
import Breadcrumbs from '../component/breadcrumbs/Breadcrumbs';  

const Contact = () => {

  let [contacts, setContacts] = useState([]);  
  let [contactdata, setContactdata] = useState({ name: '', email: '', subject: '', message:'' });    
  const [status, setStatus] = useState(undefined);

  React.useEffect(() => {                            
    RestClient.GetRequest(AppUrl.Contact).then(result=>{
      setContacts(result); 
    })   
  },[]);   


  const handleSubmit = async (e) => {
    e.preventDefault();
    try { 
        const response = await axios.post('http://127.0.0.1:8000/api/contacts', contactdata); 
        // Handle response as needed   
        setStatus(<div class="success-msg">
            <i class="fa fa-check"></i>
            Successful Form Submission 
      </div> );  
      } catch (error) {  
        console.error('Error:', error);
      }
      let emptyvals = {name: '', email: '', subject: '', message:'' };
      setContactdata(emptyvals);    
  }

  return (
    <div>
<main id="main">
 

<Breadcrumbs/>


<div class="map-section">
  <iframe style={{ border:"0", width: "100%", height: "350px" }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" allowfullscreen=""></iframe>
</div>

<section id="contact" class="contact">
  <div class="container">

    <div class="row justify-content-center aos-init aos-animate" data-aos="fade-up">

      <div class="col-lg-10">

        <div class="info-wrap">
          <div class="row">

       {contacts.map((contact) => (  
            <div class="col-lg-4 info">
              <i class={contact.contact_icon}></i> 
            {Parser(contact.contact_heading)}
            </div> 
        ))} 
        
          </div>
        </div>

      </div>

    </div>

    <div class="row mt-5 justify-content-center aos-init aos-animate" data-aos="fade-up">
      <div class="col-lg-10">
      <form onSubmit={handleSubmit} class="php-email-form" novalidate="novalidate">
         
          <div class="form-row"> 
            <div class="col-md-6 form-group">
              <input type="text" name="name" onChange={e => setContactdata({...contactdata, name: e.target.value})} value={contactdata.name} class="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars"/>
              <div class="validate"></div>
            </div>
            <div class="col-md-6 form-group">
              <input type="email" class="form-control" name="email" onChange={e => setContactdata({...contactdata, email: e.target.value})} value={contactdata.email}  id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email"/>
              <div class="validate"></div>
            </div>
          </div>
          <div class="form-group">
            <input type="text" class="form-control" name="subject" onChange={e => setContactdata({...contactdata, subject: e.target.value})} value={contactdata.subject} id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject"/>
            <div class="validate"></div>
          </div>
          <div class="form-group">
            <textarea class="form-control" name="message" onChange={e => setContactdata({...contactdata, message: e.target.value})} value={contactdata.message}  rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
            <div class="validate"></div>
          </div> 
          <div class="mb-3">
            <div class="loading">Loading</div>
            <div class="error-message"></div>
            <div class="sent-message">Your message has been sent. Thank you!</div>
          </div>
          <div class="text-center"><button type="submit">Send Message</button></div> 
        </form>
        {status} 
      
      </div>

    </div>

  </div>
</section>

</main>
    </div>
  ) 
} 

export default Contact
