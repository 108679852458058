import React from 'react'
import { BrowserRouter as Router, Routes, 
    Route,Link} from "react-router-dom"; 
import Home from "../pages/Home"; 
import About from "../pages/About";  
import Services from "../pages/Services"; 
import Portfolio from "../pages/Portfolio";   
import Pricing from "../pages/Pricing";   
import Blogs from "../pages/Blogs";  
import Contact from "../pages/Contact"; 
import Team from "../pages/Team"; 
import Testimonials from "../pages/Testimonials";
import SingleBlog from '../component/blog/Single_blog';     
 
const Header = () => { 
 
  

  return (
   
    <div>
  
  
  <header id="header" class="fixed-top">
    <div class="container d-flex align-items-center">
 
      <h1 class="logo mr-auto"><Link to="/"><span>Com</span>pany</Link></h1>
 
     <a to="/" class="logo mr-auto"><img src="assets/img/logo.png" alt="" class="img-fluid"/></a>

      <nav class="nav-menu d-none d-lg-block"> 
        <ul>
          <li class="active"><Link to="/">Home</Link></li>
 
          <li class="drop-down"><a href="">About</a>
            <ul>
              <li><Link to="/about">About Us</Link></li> 
              <li><Link to="/team">Team</Link></li>  
              <li><Link to="/testimonials">Testimonials</Link></li>
            </ul> 
          </li>

          <li><Link to="/services">Services</Link></li>
          <li><Link to="/portfolio">Portfolio</Link></li>
          <li><Link to="/pricing">Pricing</Link></li>
          <li><Link to="/blogs">Blogs</Link> </li>
          <li><Link to="/contact">Contact Us</Link></li> 
 
        </ul> 
      </nav> 

      <nav class="mobile-nav d-lg-none">
        <ul>
        <li class="active"><Link to="/">Home</Link></li>
 
 <li class="drop-down"><a href="">About</a>
   <ul>
     <li><Link to="/about">About Us</Link></li> 
     <li><Link to="/team">Team</Link></li>  
     <li><Link to="/testimonials">Testimonials</Link></li>
   </ul> 
 </li> 

 <li><Link to="/services">Services</Link></li>
 <li><Link to="/portfolio">Portfolio</Link></li>
 <li><Link to="/pricing">Pricing</Link></li>  
 <li><Link to="/blogs">Blogs</Link> </li>
 <li><Link to="/contact">Contact Us</Link></li> 

        </ul> 
      </nav> 
      

      <div class="header-social-links">
        <a href="#" class="twitter"><i class="icofont-twitter"></i></a>
        <a href="#" class="facebook"><i class="icofont-facebook"></i></a>
        <a href="#" class="instagram"><i class="icofont-instagram"></i></a>
        <a href="#" class="linkedin"><i class="icofont-linkedin"></i></a>
      </div>
      <button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>
    </div>
    
  </header> 
  <Routes> 
  <Route path="/" element={<Home />} /> 
  <Route path="/about" element={<About/>} />
  <Route path="/services" element={<Services/>} />
  <Route path="/portfolio" element={<Portfolio/>} />
  <Route path="/pricing" element={<Pricing/>} />
  <Route path="/blogs" element={<Blogs/>} /> 
  <Route path="/contact" element={<Contact/>} />
  <Route path="/team" element={<Team/>} />   
  <Route path="/testimonials" element={<Testimonials/>} />    
  <Route path="/blogsdetails/:id" element={<SingleBlog/>} />   
 
   </Routes>   

    </div>
  )
}

export default Header
