import React, { useEffect, useState } from "react";
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl'; 
import Parser from 'html-react-parser';

const About = () => {

  let [abouts, setAbouts] = useState([]);  
 
  React.useEffect(() => {     
                           
       RestClient.GetRequest(AppUrl.About).then(result=>{
       setAbouts(result);     
    })  
 
  },[]); 

  return (
    <div>
    <section id="about-us" class="about-us">
    {abouts.map((about) => (   
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2><strong>{about.about_heading}</strong></h2> 
        </div> 

        <div class="row content">
          <div class="col-lg-6" data-aos="fade-right">
            <h2>{about.about_para}</h2>
            <h3>{about.about_sub_para}</h3>
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left">
               {Parser(about.about_second_para)} 
          </div>  
        </div>
      </div>
        ))} 
    </section>
  </div>
  ) 
} 
 
export default About
