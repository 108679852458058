import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, 
  Route,Link} from "react-router-dom";

const Breadcrumbs = () => {
 
  const url = window.location.pathname;


  if(url ==='/about'){ 
    var bred = 'About Us';
  } 
  else if(url ==='/blogs') 
  {  
    var bred = 'Blogs';
  }  
  else if(url ==='/services') 
  {
    var bred = 'Services';
  } 
  else if(url ==='/team') 
  {
    var bred = 'Team';
  } 
  else if(url ==='/testimonials') 
  {
    var bred = 'Testimonials'; 
  } 

  else if(url ==='/portfolio') 
  {
    var bred = 'Portfolio';  
  } 

  else if(url ==='/contact')  
  {
    var bred = 'Contact';  
  } 


  return (
    <div>
       <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
          <h2>{bred}</h2>
          <ol>
            <li><Link to="/">Home</Link></li>
            <li>{bred}</li>
          </ol>
        </div>

      </div> 
    </section>
    </div> 
  )
}

export default Breadcrumbs
