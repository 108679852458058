import React, { useEffect, useState } from "react";
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl'; 
import Parser from 'html-react-parser';
 
const Slider = () => {
 
  let [sliders, setSlider] = useState([]); 
 
  React.useEffect(() => {                           
    RestClient.GetRequest(AppUrl.Slider).then(result=>{
      setSlider(result);
    })
  },[]); 
  
  return ( 
    <div>
       <section id="hero">
    <div id="heroCarousel" class="carousel slide carousel-fade" data-ride="carousel">

      <div class="carousel-inner" id="carousel-inner" role="listbox">

 {sliders.map((slider) => (  
  
    <div class="carousel-item" style={{backgroundImage:`url(assets/img/slide/${slider.slider_img})`}}>
     <div class="carousel-container">    
       <div class="carousel-content animate__animated animate__fadeInUp">
         <h2>{slider.slider_heading}</h2>  
         {Parser(slider.slider_read)}   
         <div class="text-center"> 
            {Parser(slider.slider_para)} 
          </div>
       </div> 
     </div> 
   </div>

 ))} 

      </div>

      <a class="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon icofont-simple-left" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>

      <a class="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
        <span class="carousel-control-next-icon icofont-simple-right" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>

      <ol class="carousel-indicators" id="hero-carousel-indicators"></ol>

    </div> 
  </section>
    </div>
  )
}


export default Slider
