import React, { useEffect, useState } from "react";
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl'; 
import Parser from 'html-react-parser'; 

const Features = () => {

  let [features, setFeatures] = useState([]);  
 
  React.useEffect(() => {                           
    RestClient.GetRequest(AppUrl.Features).then(result=>{
      setFeatures(result);    
    })      
  },[]); 

  let [informations, setInformation] = useState([]);  
    
  React.useEffect(() => {                           
    RestClient.GetRequest(AppUrl.Information).then(result=>{
      setInformation(result); 
    })
  },[]);  

  return (
    <div>
       <section id="features" class="features">
      <div class="container aos-init aos-animate" data-aos="fade-up">

      {informations.map((info) => ( 
        <div class="section-title">
          {Parser(info.feature)}
        </div>   
      ))} 

        <div class="row">
        {features.map((feature) => (
          <div class="col-lg-3 col-md-4 mt-4">
            <div class="icon-box">
              <i class={feature.feat_icon} style={{ color: "#ffbb2c"}}></i>
              <h3><a href="">{feature.feat_text}</a></h3>
            </div>
          </div>
          ))}  
        </div>
      </div>
    </section>
    </div>
  )
}

export default Features
